<template>
  <ReflectOverlay
    class="modal"
    @cancel="cancel"
  >
    <div
      class="modal__content"
      :style="modalFixedHeight ? { height: `${modalFixedHeight}px` } : {}"
      @click.stop=""
    >
      <slot name="title">
        <h4
          v-if="titleMessage"
          class="content__title"
        >
          {{ titleMessage }}
        </h4>
      </slot>

      <slot />

      <div
        v-if="hasFooterSlot"
        class="content__footer"
      >
        <slot
          class="footer__left-content"
          name="leftFooter"
        />
        <div
          v-if="cancelMessage || actionMessage"
          class="footer__actions"
        >
          <span
            v-if="disclaimer"
            class="actions__disclaimer"
          >
            {{ disclaimer }}
          </span>
          <slot name="cancel">
            <span
              v-if="cancelMessage"
              class="actions__cancel"
              @click="cancel"
            >
              {{ cancelMessage }}
            </span>
          </slot>
          <ReflectButton
            v-if="actionMessage"
            class="actions__button"
            :theme="actionButtonTheme"
            :disabled="isActionDisabled"
            :loading="isActionLoading"
            @click="handleAction"
          >
            {{ actionMessage }}
          </ReflectButton>
        </div>
      </div>

      <i
        class="content__icon icon-cross"
        @click="cancel"
      />
    </div>
  </ReflectOverlay>
</template>

<script lang="ts">
import type { SlotsType } from 'vue'

export default {
  name: 'ReflectModal',
  props: {
    titleMessage: {
      type: String,
      default: ''
    },
    actionMessage: {
      type: String,
      default: ''
    },
    cancelMessage: {
      type: String,
      default: ''
    },
    actionButtonTheme: {
      type: String,
      default: 'primary'
    },
    disclaimer: {
      type: String,
      default: ''
    },
    isActionDisabled: {
      type: Boolean,
      default: false
    },
    isActionLoading: {
      type: Boolean,
      default: false
    },
    modalFixedHeight: {
      type: Number,
      default: undefined
    }
  },
  slots: Object as SlotsType<{
    default: {}
    title: {}
    leftFooter: {}
    cancel: {}
  }>,
  emits: ['cancel', 'action'],
  computed: {
    hasFooterSlot() {
      return (
        !!this.$slots.leftFooter || !!this.cancelMessage || !!this.actionMessage
      )
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    handleAction() {
      this.$emit('action')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  background-color: $overlay-background;

  &__content {
    position: relative;
    background-color: $bg-secondary;
    border-radius: 12px;
    min-height: 120px;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $margin-intermediate $margin-intermediate $margin-regular;
    box-sizing: border-box;

    .content {
      &__title {
        @include font-text($font-weight-medium);
        @include font-size($font-size-normal);
        color: $text-secondary;
        margin-bottom: $margin-intermediate;
        // Keep space between title and modal cross icon
        padding-right: $margin-medium;
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $margin-large;

        .footer {
          &__actions {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: end;

            .actions {
              &__cancel {
                @include font-text;
                @include font-size($font-size-regular);
                color: $text-secondary;
                margin-right: $margin-regular;
                cursor: pointer;
              }

              &__disclaimer {
                @include font-text;
                @include font-size($font-size-mini);
                color: $text-secondary;
                margin-right: $margin-regular;
                width: 290px;
                position: absolute;
                bottom: $margin-intermediate;
                left: $margin-intermediate;
              }
            }
          }
        }
      }

      &__icon {
        position: absolute;
        height: 32px;
        width: 32px;
        top: 8px;
        right: 8px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-icon;
        @include font-size(16px);
        color: $text-secondary;
        cursor: pointer;
      }
    }
  }
}
</style>
